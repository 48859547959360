import {
  createRouter,
  createWebHistory,
  RouterView,
  type RouteRecordRaw,
} from "vue-router";
import { useConfigStore } from "@/stores/config";
import { useAuthStore } from "@/stores/auth";
import { documentationRoutes } from "@/router/documentationRoutes";
import { is, can } from "@/vd/helpers/permissions";
import { useToast } from "vue-toastification";
import { useProcessStore } from "@/vd/stores/process";
import type { MenuItem } from "@/core/config/MainMenuConfig";
import { useClientStore } from "@/vd/stores/clients";
import { GeneralInformationProcess } from "@/vd/data/processListItems";
import { useSettingsStore } from "@/vd/stores/settings";

const toast = useToast();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/vd/layouts/main/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/vd/views/AppDashboard.vue"),
        meta: {
          pageTitle: "Startseite",
        },
      },

      //Account
      {
        path: "/account/profile",
        name: "account-profile",
        component: () => import("@/vd/views/account/ProfileOverview.vue"),
        meta: {
          pageTitle: "Profil",
          breadcrumbs: ["Account", "Profil"],
        },
      },
      //Account MFA
      {
        path: "/account/profile/mfa",
        name: "account-profile-mfa",
        component: () => import("@/vd/views/account/TwoFactorConfig.vue"),
        meta: {
          pageTitle: "Zwei-Faktor-Authentifizierung",
          breadcrumbs: ["Account", "Profil", "Zwei-Faktor-Authentifizierung"],
        },
      },

      //Company settings
      {
        path: "/company/settings",
        name: "company-settings",
        component: () =>
          import("@/vd/views/company/settings/SettingsOverview.vue"),
        children: [
          {
            path: "presentation-docu",
            name: "settings-presentation-docu",
            component: () =>
              import("@/vd/views/company/settings/PresentationDocu.vue"),
            meta: {
              pageTitle: "Darstellung Dokumentation",
              breadcrumbs: ["Einstellungen", "Darstellung Dokumentation"],
            },
          },
          {
            path: "presentation-cover",
            name: "settings-presentation-cover",
            component: () =>
              import("@/vd/views/company/settings/PresentationCover.vue"),
            meta: {
              pageTitle: "Darstellung Deckblatt",
              breadcrumbs: ["Einstellungen", "Darstellung Deckblatt"],
            },
          },
          {
            path: "presentation-header-footer",
            name: "settings-presentation-header-footer",
            component: () =>
              import(
                "@/vd/views/company/settings/PresentationHeaderFooter.vue"
              ),
            meta: {
              pageTitle: "Darstellung Kopf-/Fußzeile",
              breadcrumbs: ["Einstellungen", "Darstellung Kopf-/Fußzeile"],
            },
          },
          {
            path: "email",
            name: "settings-email",
            component: () =>
              import("@/vd/views/company/settings/EmailSettings.vue"),
            meta: {
              pageTitle: "E-Mail-Einstellungen",
              breadcrumbs: ["Einstellungen", "E-Mail-Einstellungen"],
            },
          },
        ],
      },

      //Messages
      {
        path: "/messages",
        name: "messages",
        component: () => import("@/vd/views/messages/MessageList.vue"),
        meta: {
          pageTitle: "Nachrichten",
          breadcrumbs: ["Nachrichten"],
        },
        children: [
          //Chat
          {
            path: ":conversation_id/conversation",
            name: "conversation-messages",
            component: () =>
              import("@/vd/components/messages/ChatContainer.vue"),
          },
        ],
      },

      //Users
      {
        path: "/company/users",
        name: "users",
        component: RouterView,
        children: [
          //User list
          {
            path: "/company/users",
            name: "users-index",
            component: () => import("@/vd/views/company/users/UserList.vue"),
            meta: {
              pageTitle: "Benutzerübersicht",
              breadcrumbs: ["Benutzer"],
            },
          },

          //User create
          {
            path: "create",
            name: "create-user",
            component: () => import("@/vd/views/company/users/CreateUser.vue"),
            meta: {
              pageTitle: "Benutzer erstellen",
              breadcrumbs: ["Benutzer", "Benutzer erstellen"],
              roles: "super-admin|company-admin",
              //permissions: 'edit-user'
            },
          },

          //User edit
          {
            path: ":user_id/edit",
            name: "edit-user",
            component: () => import("@/vd/views/company/users/EditUser.vue"),
            meta: {
              pageTitle: "Benutzer bearbeiten",
              breadcrumbs: ["Benutzer", "Benutzer bearbeiten"],
              roles: "super-admin|company-admin",
            },
            children: [
              //User Account edit
              {
                path: "account",
                name: "edit-user-account",
                component: () =>
                  import("@/vd/views/company/users/edit/EditUserAccount.vue"),
                meta: {
                  pageTitle: "Benutzer-Konto bearbeiten",
                  breadcrumbs: [
                    "Benutzer",
                    "Benutzer bearbeiten",
                    "Benutzer-Konto",
                  ],
                  roles: "super-admin|company-admin",
                },
              },
              //User Permissions edit
              {
                path: "permissions",
                name: "edit-user-permissions",
                component: () =>
                  import(
                    "@/vd/views/company/users/edit/EditUserPermissions.vue"
                  ),
                meta: {
                  pageTitle: "Benutzer-Berechtigungen bearbeiten",
                  breadcrumbs: [
                    "Benutzer",
                    "Benutzer bearbeiten",
                    "Benutzer-Berechtigungen",
                  ],
                  roles: "super-admin|company-admin",
                },
              },
              //User Todos edit
              {
                path: "todos",
                name: "edit-user-todos",
                component: () =>
                  import("@/vd/views/company/users/edit/EditUserTodos.vue"),
                meta: {
                  pageTitle: "Benutzer-Todos anzeigen",
                  breadcrumbs: [
                    "Benutzer",
                    "Benutzer bearbeiten",
                    "Benutzer-Todos",
                  ],
                  roles: "super-admin|company-admin",
                },
              },
              //User Client assignment
              {
                path: "client-assignment",
                name: "edit-user-client-assignment",
                component: () =>
                  import(
                    "@/vd/views/company/users/edit/EditUserClientAssignment.vue"
                  ),
                meta: {
                  pageTitle: "Mandantenzuordnung anzeigen",
                  breadcrumbs: [
                    "Benutzer",
                    "Benutzer bearbeiten",
                    "Mandantenzuordnung",
                  ],
                  roles: "super-admin|company-admin",
                },
              },
              //User Logs edit
              {
                path: "logs",
                name: "edit-user-logs",
                component: () =>
                  import("@/vd/views/company/users/edit/EditUserLogs.vue"),
                meta: {
                  pageTitle: "Benutzer-Logs anzeigen",
                  breadcrumbs: [
                    "Benutzer",
                    "Benutzer bearbeiten",
                    "Benutzer-Logs",
                  ],
                  roles: "super-admin|company-admin",
                },
              },
            ],
          },
        ],
      },

      //Todos
      {
        path: "/todos",
        name: "todos-index",
        component: () => import("@/vd/views/todos/TodoList.vue"),
        meta: {
          pageTitle: "Aufgabenübersicht",
          breadcrumbs: ["Aufgaben"],
        },
      },

      //Clients
      {
        path: "/clients",
        name: "clients",
        component: RouterView,
        children: [
          //Client list
          {
            path: "/clients",
            name: "clients-index",
            component: () => import("@/vd/views/clients/ClientList.vue"),
            meta: {
              pageTitle: "Mandantenübersicht",
              roles: "super-admin|admin|consultant",
              breadcrumbs: ["Mandanten"],
            },
          },

          //Client create
          {
            path: "create",
            name: "create-client",
            component: () => import("@/vd/views/clients/CreateClient.vue"),
            meta: {
              pageTitle: "Mandant erstellen",
              breadcrumbs: ["Mandanten", "Mandant erstellen"],
              roles: "super-admin|admin|consultant",
            },
          },
        ],
      },

      //Company Base Data
      {
        path: "/company",
        name: "company-base-data",
        component: () =>
          import("@/vd/views/company/baseData/CompanyBaseData.vue"),
        meta: {
          pageTitle: "Stammdaten",
          roles: "super-admin|company-admin",
          breadcrumbs: ["Unternehmen"],
        },
      },
      //Administration
      {
        path: "/administration",
        name: "administration",
        component: RouterView,
        children: [
          {
            path: "/administration",
            name: "administration-overview",
            component: () =>
              import("@/vd/views/administration/AdministrationOverview.vue"),
            meta: {
              pageTitle: "Administration",
              roles: "super-admin",
              breadcrumbs: ["Administration"],
              middleware: "auth",
            },
          },
          {
            path: "processes/categories",
            name: "administration-processes",
            component: () =>
              import(
                "@/vd/views/administration/processes/AdministrationProcesses.vue"
              ),
            meta: {
              pageTitle: "Prozesse",
              roles: "super-admin",
              breadcrumbs: ["Administration", "Prozesse"],
              middleware: "auth",
            },
          },
          {
            path: "processes/groups",
            name: "administration-process-groups",
            component: () =>
              import(
                "@/vd/views/administration/processes/AdministrationProcessGroups.vue"
              ),
            meta: {
              pageTitle: "Prozess-Gruppen",
              roles: "super-admin",
              breadcrumbs: ["Administration", "Prozess-Gruppen"],
              middleware: "auth",
            },
          },
          {
            path: "processes/questions",
            name: "administration-process-questions",
            component: () =>
              import(
                "@/vd/views/administration/processes/AdministrationProcessQuestions.vue"
              ),
            meta: {
              pageTitle: "Prozess-Fragen",
              roles: "super-admin",
              breadcrumbs: ["Administration", "Prozess-Fragen"],
              middleware: "auth",
            },
          },
        ],
      },
    ],
  },

  //Documentation
  {
    path: "/verfahrensdokumentation",
    name: "company-documentation-index",
    redirect: "/verfahrensdokumentation/dashboard",
    component: () =>
      import("@/vd/layouts/documentation/DocumentationLayout.vue"),
    meta: {
      pageTitle: "Dokumentation",
      breadcrumbs: ["Dokumentation"],
      middleware: "auth",
    },
    children: documentationRoutes(),
  },

  //Documentation Client
  {
    path: "/clients/:client_id/verfahrensdokumentation",
    name: "client-documentation-index",
    redirect: "/clients/:client_id/verfahrensdokumentation/dashboard",
    component: () =>
      import("@/vd/layouts/documentation/DocumentationLayout.vue"),
    meta: {
      pageTitle: "Dokumentation",
      breadcrumbs: ["Dokumentation"],
      middleware: "auth",
    },
    children: documentationRoutes("client-"),
  },

  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/LoginUser.vue"),
        meta: {
          pageTitle: "Anmeldung",
          authPage: true,
        },
      },
      {
        path: "/two-factor",
        name: "two-factor",
        component: () => import("@/views/auth/TwoFactor.vue"),
        meta: {
          pageTitle: "Zwei-Faktor-Authentifizierung",
          authPage: true,
        },
      },
      {
        path: "/password-forgot",
        name: "password-forgot",
        component: () => import("@/views/auth/ForgotPassword.vue"),
        meta: {
          pageTitle: "Passwort vergessen",
          authPage: true,
        },
      },
      {
        path: "/password-reset/:token",
        name: "password-reset",
        component: () => import("@/views/auth/ResetPassword.vue"),
        meta: {
          pageTitle: "Passwort zurücksetzen",
          authPage: true,
        },
      },
      {
        path: "/verify-email/:id/:hash",
        name: "verify-email",
        component: () => import("@/views/auth/VerifyEmail.vue"),
        meta: {
          pageTitle: "E-Mailadresse verifizieren",
          authPage: true,
        },
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/RegisterUser.vue"),
    meta: {
      pageTitle: "Registrieren",
      authPage: true,
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/errors/ErrorPage404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/errors/ErrorPage500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

const stripTrailingSlash = (str) => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

const checkCurrentProcess = async (
  route,
  items: Array<MenuItem>,
  processStore,
  found: number = 0
) => {
  if (
    route.meta.editProcess &&
    route.params.alias === GeneralInformationProcess.alias
  ) {
    route.meta.pageTitle = GeneralInformationProcess.heading;
    route.meta.breadcrumbs = GeneralInformationProcess.breadcrumbs;
    processStore.alias = GeneralInformationProcess.alias as string;
    found++;
  } else {
    items.forEach(async (item) => {
      if (
        item.route === stripTrailingSlash(route.path) ||
        (route.meta.editProcess && route.params.alias === item.alias)
      ) {
        const crsId = route.query.crs_id;
        if (crsId !== undefined && crsId !== null && crsId !== "") {
          if (item.route?.indexOf(crsId as string) !== -1) {
            route.meta.pageTitle = item.heading;
            route.meta.breadcrumbs = item.breadcrumbs;
            processStore.alias = item.alias as string;
            found++;
          }
        } else {
          route.meta.pageTitle = item.heading;
          route.meta.breadcrumbs = item.breadcrumbs;
          processStore.alias = item.alias as string;
          found++;
        }
      } else if (item.sub && item.sub.length > 0) {
        found++;
        await checkCurrentProcess(route, item.sub, processStore, found);
      }
    });
  }
  return found;
};

router.beforeEach(async (to, from, next) => {
  const configStore = useConfigStore();

  document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_APP_NAME}`;

  configStore.resetLayoutConfig();

  if (to.meta.middleware == "auth") {
    const authStore = useAuthStore();
    const auth = await authStore.checkAuthentication();
    if (auth) {
      const roles = to.meta.roles;
      const permissions = to.meta.permissions;

      const hasRoles = roles !== undefined ? is(roles as string) : true;
      const hasPermissions =
        permissions !== undefined ? can(permissions as string) : true;

      if (
        (permissions !== undefined && hasPermissions) ||
        (roles !== undefined && hasRoles) ||
        (permissions === undefined && roles === undefined)
      ) {
        /* if(to.meta.breadcrumbs && Array.isArray(to.meta.breadcrumbs) && to.meta.breadcrumbs[0] == 'Verfahrensdokumentation') {  */

        if (to.params.client_id !== undefined) {
          const clientStore = useClientStore();
          await clientStore.getSingleClient(to.params.client_id as string);
        }

        if (to.meta.docu) {
          const processStore = useProcessStore();
          try {
            let menuItems = processStore.processMenuItems;
            if (!processStore.processMenuItemsLoaded || !menuItems) {
              menuItems = await processStore.queryProcessMenuItems();
            }

            if (to.meta.process) {
              if (menuItems) {
                const response = await checkCurrentProcess(
                  to,
                  menuItems,
                  processStore
                );

                if (response === 0) {
                  next({ name: "404" });
                }
              }
            }
          } catch (error) {
            toast.error("Laden der Prozesse fehlgeschlagen");
          }
        }

        next();
      } else {
        toast.error("Zugriff verweigert!");
        next({ name: "dashboard" });
      }
    } else {
      sessionStorage.setItem("redirectPath", to.path);
      next({ name: "login" });
    }
  } else {
    if (to.meta.authPage !== undefined && to.meta.authPage === true) {
      const authStore = useAuthStore();
      const settingsStore = useSettingsStore();

      const auth = await authStore.checkAuthentication();

      if (auth) {
        await settingsStore.querySettings();
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else {
      next();
    }
  }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
