import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

import type { User } from "@/vd/data/user";
import { ref } from "vue";
import type { Settings } from "@/vd/data/settings";
import type { Todo } from "@/vd/data/todos";
import type { Notification } from "@/vd/data/notificationLog";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: ref<User>({} as User),
    settings: ref<Settings>({} as Settings),
    todos: ref<Array<Todo>>([] as Array<Todo>),
    notifications: ref<Array<Notification>>([] as Array<Notification>),
    passwordConfirmed: ref<Boolean>(false),
  }),

  getters: {
    getUser: (state) => state.user,
    getSettings: (state) => state.settings,
    getPasswordConfirmationStatus: (state) => state.passwordConfirmed,
    getTodos: (state) => state.todos,
    getNotifications: (state) => state.notifications,
  },

  actions: {
    async login(credentials) {
      try {
        await this.logout(false);
        const response = await ApiService.post("/login", credentials);

        if (response.data.two_factor === true) {
          localStorage.setItem("twoFactor", "true");
          return "two_factor";
        }

        localStorage.setItem("isAuthenticated", "true");

        const checkAuth = await this.checkAuthentication();

        if (checkAuth === true) {
          return true;
        } else if (checkAuth === false) {
          return false;
        } else {
          throw checkAuth;
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }

        throw error;
      }
    },

    async register(registerationValues) {
      try {
        return ApiService.post("/register", registerationValues);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async logout(redirect: boolean = true) {
      try {
        await ApiService.post("/logout", null);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }

      this.removeSession();
      if (redirect) {
        router.push("/login");
      }
    },

    async removeSession() {
      this.user = {} as User;
      this.settings = {} as Settings;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("twoFactor");

      window.vdPermissions.jsonPermissions = 0;
    },

    async checkAuthentication() {
      try {
        const isAuthenticated = localStorage.getItem("isAuthenticated");
        if (isAuthenticated) {
          const response = await ApiService.get("/api/v1/user");
          this.user = response.data.user;
          this.settings = response.data.settings;
          window.vdPermissions["jsonPermissions"] = JSON.parse(
            response.data.permissions
          );
          return true;
        }

        this.removeSession();

        return false;
      } catch (error) {
        this.removeSession();
        if (error instanceof Error) {
          console.error(error.message);
        }
        return false;
      }
    },

    async verifyEmail(route: any) {
      try {
        const params = new URLSearchParams(route.query);
        const paramString = params.toString();

        const response = await ApiService.get(
          "email/verify/" +
            route.params.id +
            "/" +
            route.params.hash +
            "?" +
            paramString
        );
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async resendEmail() {
      try {
        await ApiService.post("email/verification-notification", null);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async forgotPassword(values: any) {
      try {
        await ApiService.post("/forgot-password", values);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async resetPassword(values: any) {
      try {
        await ApiService.post("/reset-password", values);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async confirmPassword(values: any) {
      try {
        await ApiService.post("/user/confirm-password", values);
        await this.confirmPasswordStatus();
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        this.passwordConfirmed = false;
        throw error;
      }
    },

    async confirmPasswordStatus() {
      try {
        const response = await ApiService.get(
          "/user/confirmed-password-status"
        );
        if (response.data.confirmed !== undefined) {
          this.passwordConfirmed = response.data.confirmed;
        }
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        this.passwordConfirmed = false;
        throw error;
      }
    },

    async enableMfa() {
      try {
        await this.confirmPasswordStatus();
        const response = await ApiService.post(
          "/user/two-factor-authentication",
          null
        );
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async disableMfa() {
      try {
        await this.confirmPasswordStatus();
        await ApiService.delete("/user/two-factor-authentication");
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async getMfaQrCode() {
      try {
        await this.confirmPasswordStatus();
        const response = await ApiService.get("/user/two-factor-qr-code");
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async getMfaSecretKey() {
      try {
        await this.confirmPasswordStatus();
        const response = await ApiService.get("/user/two-factor-secret-key");
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async getMfaRecoveryCodes() {
      try {
        await this.confirmPasswordStatus();
        const response = await ApiService.get(
          "/user/two-factor-recovery-codes"
        );
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async renewMfaRecoveryCodes() {
      try {
        await this.confirmPasswordStatus();
        await ApiService.post("/user/two-factor-recovery-codes", null);
        const response = await ApiService.get(
          "/user/two-factor-recovery-codes"
        );
        return response;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async mfaChallenge(values: any) {
      try {
        await ApiService.post("/two-factor-challenge", values);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.removeItem("twoFactor");

        await this.checkAuthentication();
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async confirmMfa(values: any) {
      try {
        await ApiService.post(
          "/user/confirmed-two-factor-authentication",
          values
        );
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },
  },
});
