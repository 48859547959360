import type { App } from "vue";
import type { AxiosResponse } from "axios";
import axios from "axios";
import VueAxios from "vue-axios";
import { useCookies } from "vue3-cookies";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.withCredentials = true;
    ApiService.vueInstance.axios.defaults.baseURL =
      import.meta.env.VITE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
    ApiService.vueInstance.axios.defaults.xsrfCookieName = "XSRF-TOKEN";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static async csrf() {
    const { cookies } = useCookies();
    const xsrfCookie = cookies.get("XSRF-TOKEN");

    ApiService.vueInstance.axios.defaults.headers.common["X-XSRF-TOKEN"] =
      xsrfCookie;
    await ApiService.get("/sanctum/csrf-cookie");
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, { params: params });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    if (slug) {
      return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    }
    return ApiService.vueInstance.axios.get(resource);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: any,
    file: boolean = false
  ): Promise<AxiosResponse> {
    return ApiService.csrf().then(() => {
      let headers = {};
      if (file) {
        headers = {
          "Content-Type": "multipart/form-data",
        };
      }
      return ApiService.vueInstance.axios.post(`${resource}`, params, {
        headers,
      });
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    params: any,
    slug?: string,
    file?: boolean
  ): Promise<AxiosResponse> {
    if (slug) {
      resource = `${resource}/${slug}`;
    }
    params["_method"] = "patch";

    return ApiService.csrf().then(() => {
      if (file) {
        return ApiService.vueInstance.axios.post(resource, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      return ApiService.vueInstance.axios.post(resource, params);
    });
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.csrf().then(() => {
      return ApiService.vueInstance.axios.put(`${resource}`, params);
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.csrf().then(() => {
      return ApiService.vueInstance.axios.delete(resource);
    });
  }

  /* public static getClientOrCompanyData(resource: string,slug = "" as string): Promise<AxiosResponse> {
    const route = useRoute();

    const clientId = route ? route.params.client_id as string : null;
    let prefix = "/api/v1/";

    if(clientId) {
      prefix = "/api/v1/client/" + clientId + "/";
    }
    if(slug) {
      return ApiService.vueInstance.axios.get(`${prefix}${resource}/${slug}`);
    }
    return ApiService.vueInstance.axios.get(prefix+resource);
  } */
}

export default ApiService;
