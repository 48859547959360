import { RouterView, type RouteRecordRaw } from "vue-router";

function documentationRoutes(prefix: string = ""): Array<RouteRecordRaw> {
  const documentationRoutes: Array<RouteRecordRaw> = [
    {
      path: "dashboard",
      name: prefix + "verfahrensdokumentation-dashboard",
      component: () =>
        import("@/vd/views/verfahrensdokumentation/DocuDashboard.vue"),
      meta: {
        pageTitle: "Dokumentations-Übersicht",
        breadcrumbs: ["Verfahrensdokumentation", "Übersicht"],
        //roles: 'super-admin|company-admin',
        client: prefix ? true : false,
        docu: true,
      },
    },
    {
      path: "preview",
      name: prefix + "verfahrensdokumentation-preview",
      component: () =>
        import("@/vd/views/verfahrensdokumentation/DocuPreview.vue"),
      meta: {
        pageTitle: "Dokumentations-Vorschau",
        breadcrumbs: ["Verfahrensdokumentation", "Vorschau"],
        //roles: 'super-admin|company-admin',
        client: prefix ? true : false,
        docu: true,
      },
    },
    {
      path: "progress",
      name: prefix + "verfahrensdokumentation-progress",
      component: () =>
        import("@/vd/views/verfahrensdokumentation/DocuProgress.vue"),
      meta: {
        pageTitle: "Dokumentations-Fortschritt",
        breadcrumbs: ["Verfahrensdokumentation", "Fortschritt"],
        //roles: 'super-admin|company-admin',
        client: prefix ? true : false,
        docu: true,
      },
    },
    {
      path: "iks",
      name: prefix + "verfahrensdokumentation-iks",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/internalControlSystems/InternalControlSystem.vue"
        ),
      meta: {
        pageTitle: "Internes Kontrollsystem",
        breadcrumbs: ["Verfahrensdokumentation", "Internes Kontrollsystem"],
        //roles: 'super-admin|company-admin',
        client: prefix ? true : false,
        docu: true,
      },
    },
    {
      path: "process-details/:alias",
      name: prefix + "verfahrensdokumentation-edit-process",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/processes/ProcessDetails.vue"
        ),
      meta: {
        pageTitle: "Prozess bearbeiten",
        breadcrumbs: ["Verfahrensdokumentation", "Prozesse"],
        permissions: "all-docu-permissions|edit-processes",
        client: prefix ? true : false,
        process: true,
        editProcess: true,
        docu: true,
      },
    },
    {
      path: "processes/:process1?/:process2?/:process3?/:process4?",
      name: prefix + "verfahrensdokumentation-processes",
      component: () =>
        import("@/vd/views/verfahrensdokumentation/processes/ProcessList.vue"),
      meta: {
        pageTitle: "Prozess-Übersicht",
        breadcrumbs: ["Verfahrensdokumentation", "Prozesse"],
        permissions: "all-docu-permissions|edit-processes|show-processes",
        client: prefix ? true : false,
        process: true,
        docu: true,
      },
    },
    {
      path: "custom-processes/:uuid/edit",
      name: prefix + "verfahrensdokumentation-custom-process",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/customProcesses/EditCustomProcess.vue"
        ),
      meta: {
        pageTitle: "Individuellen Prozess bearbeiten",
        breadcrumbs: [
          "Verfahrensdokumentation",
          "Prozesse",
          "Individueller Prozess",
        ],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-processes",
        docu: true,
      },
    },
    {
      path: "locations",
      name: prefix + "locations",
      component: () =>
        import("@/vd/views/verfahrensdokumentation/locations/LocationList.vue"),
      meta: {
        pageTitle: "Standorte",
        breadcrumbs: ["Verfahrensdokumentation", "Standorte"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-locations",
        docu: true,
      },
    },
    {
      path: "organizational-units",
      name: prefix + "organizationalUnits",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/organizationalUnits/OrganizationalUnit.vue"
        ),
      meta: {
        pageTitle: "Organisationseinheiten",
        breadcrumbs: ["Verfahrensdokumentation", "Organisationseinheiten"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-organizational-units",
        docu: true,
      },
    },
    {
      path: "hardware",
      component: RouterView,
      meta: {
        pageTitle: "Hardware",
        breadcrumbs: ["Verfahrensdokumentation", "Hardware"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-hardware",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "hardware",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/hardware/HardwareList.vue"
            ),
          meta: {
            pageTitle: "Hardware",
            breadcrumbs: ["Verfahrensdokumentation", "Hardware"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-hardware",
            docu: true,
          },
        },
        {
          path: ":uuid/edit",
          name: prefix + "edit-hardware",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/hardware/EditHardware.vue"
            ),
          meta: {
            pageTitle: "Hardware bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Hardware",
              "Hardware bearbeiten",
            ],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-hardware",
            docu: true,
          },
        },
      ],
    },
    {
      path: "additional-attachments",
      component: RouterView,
      meta: {
        pageTitle: "Sonstige Anlagen",
        breadcrumbs: ["Verfahrensdokumentation", "Sonstige Anlagen"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-attachments",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "additional-attachments",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/additionalAttachments/AdditionalAttachmentList.vue"
            ),
          meta: {
            pageTitle: "Sonstige Anlagen",
            breadcrumbs: ["Verfahrensdokumentation", "Sonstige Anlagen"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-attachments",
            docu: true,
          },
        },
        {
          path: ":uuid/edit",
          name: prefix + "edit-hardware",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/hardware/EditHardware.vue"
            ),
          meta: {
            pageTitle: "Hardware bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Hardware",
              "Hardware bearbeiten",
            ],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-hardware",
            docu: true,
          },
        },
      ],
    },

    {
      path: "employees",
      name: prefix + "employees",
      component: () =>
        import("@/vd/views/verfahrensdokumentation/employees/EmployeeList.vue"),
      meta: {
        pageTitle: "Mitarbeiter",
        breadcrumbs: ["Verfahrensdokumentation", "Mitarbeiter"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-employees",
        docu: true,
      },
    },
    {
      path: "employees/positions-departments",
      name: prefix + "employee-positions-departments",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/employees/PositionsDepartments.vue"
        ),
      meta: {
        pageTitle: "Positionen und Abteilungen",
        breadcrumbs: ["Verfahrensdokumentation", "Positionen und Abteilungen"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-departments|edit-positions",
        docu: true,
      },
    },
    {
      path: "service-providers",
      name: prefix + "service-providers",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/serviceProvider/ServiceProviderList.vue"
        ),
      meta: {
        pageTitle: "Dienstleister",
        breadcrumbs: ["Verfahrensdokumentation", "Dienstleister"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-service-providers",
        docu: true,
      },
    },
    {
      path: "software",
      component: RouterView,
      meta: {
        pageTitle: "Software",
        breadcrumbs: ["Verfahrensdokumentation", "Software"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-software",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "software",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/overview/SoftwareList.vue"
            ),
          meta: {
            pageTitle: "Software",
            breadcrumbs: ["Verfahrensdokumentation", "Software"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-software",
            docu: true,
          },
        },
        {
          path: ":uuid/edit",
          name: prefix + "edit-software",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/overview/EditSoftware.vue"
            ),
          meta: {
            pageTitle: "Software bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Software",
              "Software bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-software",
          },
        },
      ],
    },
    {
      path: "software/presystems",
      component: RouterView,
      meta: {
        pageTitle: "Vorsystem",
        breadcrumbs: ["Verfahrensdokumentation", "Vorsystem"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-presystems",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "presystems",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/presystems/PresystemList.vue"
            ),
          meta: {
            pageTitle: "Vorsystem",
            breadcrumbs: ["Verfahrensdokumentation", "Vorsystem"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-presystems",
            docu: true,
          },
        },
        {
          path: ":uuid/edit",
          name: prefix + "edit-presystem",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/presystems/EditPresystem.vue"
            ),
          meta: {
            pageTitle: "Vorsystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Vorsystem",
              "Vorsystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-presystems",
          },
        },
      ],
    },
    {
      path: "software/presystems/connections",
      component: RouterView,
      meta: {
        pageTitle: "Schnittstellen",
        breadcrumbs: ["Verfahrensdokumentation", "Schnittstellen"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-connections",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "connections",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/connections/ConnectionList.vue"
            ),
          meta: {
            pageTitle: "Schnittstellen",
            breadcrumbs: ["Verfahrensdokumentation", "Schnittstellen"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-connections",
            docu: true,
          },
        },
        {
          path: "create",
          name: prefix + "connections/create",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/connections/CreateConnection.vue"
            ),
          meta: {
            pageTitle: "Schnittstelle erstellen",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Schnittstellen",
              "Schnittstelle erstellen",
            ],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-connections",
            docu: true,
          },
        },
        {
          path: ":uuid/edit",
          name: prefix + "edit-connections",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/presystems/EditPresystem.vue"
            ),
          meta: {
            pageTitle: "Schnittstellen bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Schnittstellen",
              "Schnittstellen bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-connections",
          },
        },
      ],
    },
    {
      path: "software/presystems/framework-data",
      component: RouterView,
      meta: {
        pageTitle: "Rahmendaten",
        breadcrumbs: ["Verfahrensdokumentation", "Rahmendaten"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-presystems",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "framework-data",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/software/frameworkData/FrameworkData.vue"
            ),
          meta: {
            pageTitle: "Rahmendaten",
            breadcrumbs: ["Verfahrensdokumentation", "Rahmendaten"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-presystems",
            docu: true,
          },
        },
      ],
    },
    {
      path: "cash-register-systems",
      name: prefix + "cash-register-systems",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/CashRegisterSystems.vue"
        ),
      meta: {
        pageTitle: "Kassensysteme",
        breadcrumbs: ["Verfahrensdokumentation", "Kassensysteme"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-cash-register-systems",
        docu: true,
      },
    },
    {
      path: "cash-register-systems/:uuid/edit",
      name: prefix + "edit-cash-register-systems",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/EditCashRegisterSystem.vue"
        ),
      meta: {
        pageTitle: "Kassensystem bearbeiten",
        breadcrumbs: [
          "Verfahrensdokumentation",
          "Kassensysteme",
          "Kassensystem bearbeiten",
        ],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-cash-register-systems",
        docu: true,
      },
      children: [
        //cash-register hardware
        {
          path: "hardware",
          name: prefix + "edit-cash-register-hardware",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/CashRegisterHardware/CashRegisterSystemHardware.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },
        //cash-register software
        {
          path: "software",
          name: prefix + "edit-cash-register-software",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/CashRegisterSoftware/CashRegisterSoftware.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },
        //cash-register storage location
        {
          path: "storage-location",
          name: prefix + "edit-cash-register-storage-location",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/StorageLocation/StorageLocations.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },
        //cash-register employee authorization
        {
          path: "employee-authorization",
          name: prefix + "edit-cash-register-employee-authorization",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/EmployeeAuthorization/EmployeeAuthorization.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },

        //cash-register cash instruction
        {
          path: "cash-instruction",
          name: prefix + "edit-cash-register-cash-instruction",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/CashRegisterInstructions/CashRegisterInstructions.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },
        //cash-register cash inspection
        {
          path: "cash-inspection",
          name: prefix + "edit-cash-register-cash-inspection",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/CashRegisterInspection/CashRegisterInspections.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },
        //cash-register cash files
        {
          path: "cash-files",
          name: prefix + "edit-cash-register-cash-files",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/cashRegisterSystems/overview/edit/CashRegisterFiles/CashRegisterFiles.vue"
            ),
          meta: {
            pageTitle: "Kassensystem bearbeiten",
            breadcrumbs: [
              "Verfahrensdokumentation",
              "Kassensysteme",
              "Kassensystem bearbeiten",
            ],
            permissions: "all-docu-permissions|edit-cash-register-systems",
          },
        },
      ],
    },
    //privacy policy
    {
      path: "privacy-policy",
      component: RouterView,
      meta: {
        pageTitle: "Datenschutz",
        breadcrumbs: ["Verfahrensdokumentation", "Datenschutz"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-privacy-policies",
        docu: true,
      },
      children: [
        {
          path: "",
          name: prefix + "privacy-policies",
          component: () =>
            import(
              "@/vd/views/verfahrensdokumentation/privacyPolicy/PrivacyPolicy.vue"
            ),
          meta: {
            pageTitle: "Datenschutz",
            breadcrumbs: ["Verfahrensdokumentation", "Datenschutz"],
            client: prefix ? true : false,
            permissions: "all-docu-permissions|edit-privacy-policies",
            docu: true,
          },
        },
        /* {
          path: ":slug/edit",
          name: prefix+"edit-hardware",
          component: () => import("@/vd/views/verfahrensdokumentation/hardware/EditHardware.vue"),
          meta: {
            pageTitle: "Hardware bearbeiten",
            breadcrumbs: ["Verfahrensdokumentation", "Hardware", "Hardware bearbeiten"],
            client: prefix ? true : false,
            permissions: 'all-docu-permissions|edit-privacy-policies',
            docu: true,
          }
        }, */
      ],
    },
    {
      path: "cash-register-systems/cash-register-providers",
      name: prefix + "cash-register-providers",
      component: () =>
        import(
          "@/vd/views/verfahrensdokumentation/cashRegisterSystems/CashRegisterProviders/CashRegisterProviderList.vue"
        ),
      meta: {
        pageTitle: "Kassenaufsteller",
        breadcrumbs: ["Verfahrensdokumentation", "Kassenaufsteller"],
        roles: "super-admin|company-admin",
        client: prefix ? true : false,
        permissions: "all-docu-permissions|edit-cash-register-systems",
        docu: true,
      },
    },
    {
      path: "logs",
      name: prefix + "logs",
      component: () => import("@/vd/views/company/logs/LogOverview.vue"),
      meta: {
        pageTitle: "Logübersicht",
        breadcrumbs: ["Logs"],
        client: prefix ? true : false,
        docu: true,
      },
    },
    {
      path: "logs/history",
      name: prefix + "logs-history",
      component: () => import("@/vd/views/company/logs/history/HistoryLog.vue"),
      meta: {
        pageTitle: "Änderungshistorie",
        breadcrumbs: ["Logs", "Änderungshistorie"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|show-logs",
        docu: true,
      },
    },
    {
      path: "logs/notifications",
      name: prefix + "logs-notifications",
      component: () =>
        import("@/vd/views/company/logs/notifications/NotificationLog.vue"),
      meta: {
        pageTitle: "Benachrichtigungen",
        breadcrumbs: ["Logs", "Benachrichtigungen"],
        client: prefix ? true : false,
        docu: true,
      },
    },
    {
      path: "logs/extended-logs",
      name: prefix + "logs-extended",
      component: () =>
        import("@/vd/views/company/logs/extendedLogs/ExtendedLog.vue"),
      meta: {
        pageTitle: "Erweiterte Logs",
        breadcrumbs: ["Logs", "Erweiterte Logs"],
        client: prefix ? true : false,
        permissions: "all-docu-permissions|show-logs",
        docu: true,
      },
    },
  ];
  return documentationRoutes;
}

export { documentationRoutes };
