declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    is: any;
    can: any;
  }
}
function can(value: string) {
  if (window.vdPermissions.jsonPermissions == 0) {
    return false;
  }
  const permissions = window.vdPermissions.jsonPermissions.permissions;
  let _return = false;
  if (!Array.isArray(permissions)) {
    return false;
  }
  if (value.includes("|")) {
    value.split("|").forEach(function (item) {
      if (permissions.includes(item.trim())) {
        _return = true;
      }
    });
  } else if (value.includes("&")) {
    _return = true;
    value.split("&").forEach(function (item) {
      if (!permissions.includes(item.trim())) {
        _return = false;
      }
    });
  } else {
    _return = permissions.includes(value.trim());
  }
  return _return;
}

function is(value: string) {
  if (window.vdPermissions.jsonPermissions == 0) {
    return false;
  }
  const roles = window.vdPermissions.jsonPermissions.roles;
  let _return = false;
  if (!Array.isArray(roles)) {
    return false;
  }
  if (value.includes("|")) {
    value.split("|").forEach(function (item) {
      if (roles.includes(item.trim())) {
        _return = true;
      }
    });
  } else if (value.includes("&")) {
    _return = true;
    value.split("&").forEach(function (item) {
      if (!roles.includes(item.trim())) {
        _return = false;
      }
    });
  } else {
    _return = roles.includes(value.trim());
  }
  return _return;
}

export default {
  install: (app) => {
    app.config.globalProperties.can = can;
    app.config.globalProperties.is = is;

    const permissions = { is, can };

    app.provide("permissionFunctions", { permissions });
  },
};

export { is, can };
