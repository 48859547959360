<template>
  <RouterView />
</template>

<script setup lang="ts">
import { nextTick, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useBodyStore } from "@/stores/body";
import { initializeComponents } from "@/core/plugins/keenthemes";

const bodyStore = useBodyStore();

onMounted(() => {
  nextTick(() => {
    initializeComponents();

    bodyStore.removeBodyClassName("page-loading");
  });
});
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/dist/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "vue-toastification/dist/index.css";
@import "vue-loading-overlay/dist/css/index.css";
@import "ckeditor5/ckeditor5.css";
@import "assets/sass/style";
#app {
  display: contents;
}
</style>
